const CountryMock = [
  {
    name: "Afganistán",
  },
  {
    name: "Albania",
  },
  {
    name: "Argelia",
  },
  {
    name: "Samoa Americana",
  },
  {
    name: "Andorra",
  },
  {
    name: "Angola",
  },
  {
    name: "Anguilla",
  },
  {
    name: "Antártida",
  },
  {
    name: "Antigua y Barbuda",
  },
  {
    name: "Argentina",
  },
  {
    name: "Armenia",
  },
  {
    name: "Aruba",
  },
  {
    name: "Australia",
  },
  {
    name: "Austria",
  },
  {
    name: "Azerbaiyán",
  },
  {
    name: "Bahamas",
  },
  {
    name: "Baréin",
  },
  {
    name: "Banglades",
  },
  {
    name: "Barbados",
  },
  {
    name: "Bielorrusia",
  },
  {
    name: "Bélgica",
  },
  {
    name: "Belice",
  },
  {
    name: "Benin",
  },
  {
    name: "Bermudas",
  },
  {
    name: "Butan",
  },
  {
    name: "Bolivia",
  },
  {
    name: "Bosnia-Herzegovina",
  },
  {
    name: "Botsuana",
  },
  {
    name: "Brasil",
  },
  {
    name: "Territorio Británico del Océano Índico",
  },
  {
    name: "Brunei",
  },
  {
    name: "Bulgaria",
  },
  {
    name: "Burkina Faso",
  },
  {
    name: "Burundi",
  },
  {
    name: "Camboya",
  },
  {
    name: "Camerún",
  },
  {
    name: "Canadá",
  },
  {
    name: "Cabo Verde",
  },
  {
    name: "Islas Caimán",
  },
  {
    name: "República Centroafricana",
  },
  {
    name: "Chad",
  },
  {
    name: "Chile",
  },
  {
    name: "China",
  },
  {
    name: "Isla de Navidad",
  },
  {
    name: "Islas Cocos",
  },
  {
    name: "Colombia",
  },
  {
    name: "Comoras",
  },
  {
    name: "Congo",
  },
  {
    name: "República Democrática del Congo",
  },
  {
    name: "Islas Cook",
  },
  {
    name: "Costa Rica",
  },
  {
    name: "Costa de Marfil",
  },
  {
    name: "Croacia",
  },
  {
    name: "Cuba",
  },
  {
    name: "Chipre",
  },
  {
    name: "Chequia",
  },
  {
    name: "Dinamarca",
  },
  {
    name: "Yibuti",
  },
  {
    name: "Dominica",
  },
  {
    name: "República Dominicana",
  },
  {
    name: "Ecuador",
  },
  {
    name: "Egipto",
  },
  {
    name: "El Salvador",
  },
  {
    name: "Guinea Ecuatorial",
  },
  {
    name: "Eritrea",
  },
  {
    name: "Estonia",
  },
  {
    name: "Etiopía",
  },
  {
    name: "Islas Malvinas",
  },
  {
    name: "Islas Feroe",
  },
  {
    name: "Fiyi",
  },
  {
    name: "Finlandia",
  },
  {
    name: "Francia",
  },
  {
    name: "Guayana Francesa",
  },
  {
    name: "Polinesia Francesa",
  },
  {
    name: "Gabón",
  },
  {
    name: "Gambia",
  },
  {
    name: "Georgia",
  },
  {
    name: "Alemania",
  },
  {
    name: "Ghana",
  },
  {
    name: "Gibraltar",
  },
  {
    name: "Grecia",
  },
  {
    name: "Groenlandia",
  },
  {
    name: "Granada",
  },
  {
    name: "Guadalupe",
  },
  {
    name: "Guam",
  },
  {
    name: "Guatemala",
  },
  {
    name: "Guernsey",
  },
  {
    name: "Guinea",
  },
  {
    name: "Guinea-Bisau",
  },
  {
    name: "Guyana",
  },
  {
    name: "Haití",
  },
  {
    name: "Ciudad del Vaticano",
  },
  {
    name: "Honduras",
  },
  {
    name: "Hong Kong",
  },
  {
    name: "Hungría",
  },
  {
    name: "Islandia",
  },
  {
    name: "India",
  },
  {
    name: "Indonesia",
  },
  {
    name: "Irán",
  },
  {
    name: "Iraq",
  },
  {
    name: "Irlanda",
  },
  {
    name: "Isla de Man",
  },
  {
    name: "Israel",
  },
  {
    name: "Italia",
  },
  {
    name: "Jamaica",
  },
  {
    name: "Japón",
  },
  {
    name: "Jersey",
  },
  {
    name: "Jordania",
  },
  {
    name: "Kazajistán",
  },
  {
    name: "Kenia",
  },
  {
    name: "Kiribati",
  },
  {
    name: "Corea del Norte",
  },
  {
    name: "Corea del Sur",
  },
  {
    name: "Kosovo",
  },
  {
    name: "Kuwait",
  },
  {
    name: "Kirguistán",
  },
  {
    name: "Laos",
  },
  {
    name: "Letonia",
  },
  {
    name: "Líbano",
  },
  {
    name: "Lesoto",
  },
  {
    name: "Liberia",
  },
  {
    name: "Libia",
  },
  {
    name: "Liechtenstein",
  },
  {
    name: "Lituania",
  },
  {
    name: "Luxemburgo",
  },
  {
    name: "Macao",
  },
  {
    name: "República de Macedonia",
  },
  {
    name: "Madagascar",
  },
  {
    name: "Malaui",
  },
  {
    name: "Malasia",
  },
  {
    name: "Maldivas",
  },
  {
    name: "Malí",
  },
  {
    name: "Malta",
  },
  {
    name: "Islas Marshall",
  },
  {
    name: "Martinica",
  },
  {
    name: "Mauritania",
  },
  {
    name: "Mauricio",
  },
  {
    name: "Mayotte",
  },
  {
    name: "México",
  },
  {
    name: "Estados Federados de Micronesia",
  },
  {
    name: "Moldavia",
  },
  {
    name: "Monaco",
  },
  {
    name: "Mongolia",
  },
  {
    name: "Montenegro",
  },
  {
    name: "Montserrat",
  },
  {
    name: "Marruecos",
  },
  {
    name: "Mozambique",
  },
  {
    name: "Birmania",
  },
  {
    name: "Namibia",
  },
  {
    name: "Nauru",
  },
  {
    name: "Nepal",
  },
  {
    name: "Holanda",
  },
  {
    name: "Antillas Holandesas",
  },
  {
    name: "Nueva Caledonia",
  },
  {
    name: "Nueva Zelanda",
  },
  {
    name: "Nicaragua",
  },
  {
    name: "Niger",
  },
  {
    name: "Nigeria",
  },
  {
    name: "Niue",
  },
  {
    name: "IslaNorfolk",
  },
  {
    name: "IslasMarianasdelNorte",
  },
  {
    name: "Noruega",
  },
  {
    name: "Omán",
  },
  {
    name: "Pakistán",
  },
  {
    name: "Palaos",
  },
  {
    name: "Panamá",
  },
  {
    name: "Papúa Nueva Guinea",
  },
  {
    name: "Paraguay",
  },
  {
    name: "Perú",
  },
  {
    name: "Filipinas",
  },
  {
    name: "Islas Pitcairn",
  },
  {
    name: "Polonia",
  },
  {
    name: "Portugal",
  },
  {
    name: "Puerto Rico",
  },
  {
    name: "Qatar",
  },
  {
    name: "Rumania",
  },
  {
    name: "Rusia",
  },
  {
    name: "Ruanda",
  },
  {
    name: "Reunion",
  },
  {
    name: "San Bartolome",
  },
  {
    name: "Santa Elena, Ascensión y Tristán de Acuña",
  },
  {
    name: "San Cristóbal y Nieves",
  },
  {
    name: "Santa Lucía",
  },
  {
    name: "Isla de San Martín",
  },
  {
    name: "San Pedro y Miquelon",
  },
  {
    name: "San Vicente y las Granadinas",
  },
  {
    name: "Samoa",
  },
  {
    name: "San Marino",
  },
  {
    name: " Santo Tomé y Príncipe",
  },
  {
    name: "Arabia Saudita",
  },
  {
    name: "Senegal",
  },
  {
    name: "Serbia",
  },
  {
    name: "Seychelles",
  },
  {
    name: "Sierra Leona",
  },
  {
    name: "Singapur",
  },
  {
    name: "Eslovaquia",
  },
  {
    name: "Eslovenia",
  },
  {
    name: "Islas Salomón",
  },
  {
    name: "Somalia",
  },
  {
    name: "Sudáfrica",
  },
  {
    name: "Sudán del Sur",
  },
  {
    name: "España",
  },
  {
    name: "Sri Lanka",
  },
  {
    name: "Estado de Palestina",
  },
  {
    name: "Sudán",
  },
  {
    name: "Surinam",
  },
  {
    name: "Svalbard y Jan Mayen",
  },
  {
    name: "Suazilandia",
  },
  {
    name: "Suecia",
  },
  {
    name: "Suiza",
  },
  {
    name: "Siria",
  },
  {
    name: "Taiwán",
  },
  {
    name: "Tayikistán",
  },
  {
    name: "Tanzania",
  },
  {
    name: "Tailandia",
  },
  {
    name: "Timor Oriental",
  },
  {
    name: "Togo",
  },
  {
    name: "Tokelau",
  },
  {
    name: "Tonga",
  },
  {
    name: "Trinidad y Tobago",
  },
  {
    name: "Túnez",
  },
  {
    name: "Turquía",
  },
  {
    name: "Turkmenistán",
  },
  {
    name: "Islas Turcas y Caicos",
  },
  {
    name: "Tuvalu",
  },
  {
    name: "Uganda",
  },
  {
    name: "Ucrania",
  },
  {
    name: "Emiratos Árabes Unidos",
  },
  {
    name: "Reino Unido",
  },
  {
    name: "Estados Unidos",
  },
  {
    name: "Uruguay",
  },
  {
    name: "Uzbekistán",
  },
  {
    name: "Vanuatu",
  },
  {
    name: "Venezuela",
  },
  {
    name: "Vietnam",
  },
  {
    name: "Islas Vírgenes Británicas",
  },
  {
    name: "Islas Vírgenes de los Estados Unidos",
  },
  {
    name: "Wallis y Futuna",
  },
  {
    name: "Yemen",
  },
  {
    name: "Zambia",
  },
  {
    name: "Zimbabue",
  },
  {
    name: "Åland",
  },
];

export const SelectCountry = () => {
  const countriesObject = {};
  CountryMock.forEach((item) => {
    const countryName = item.name.toLowerCase().replace(/\s/g, "_");
    countriesObject[countryName] = item.name;
  });
  return countriesObject;
};
