import React from "react";

const ModalHeader = ({ title, id, onClick = () => {} }) => {
  return (
    <div class="modal-header">
      <h4 class="modal-title" id={id}>
        {title}
      </h4>
      <button
        type="button"
        class="close"
        data-dismiss="modal"
        aria-hidden="true"
        onClick={onClick}
      >
        &times;
      </button>
    </div>
  );
};

export default ModalHeader;
