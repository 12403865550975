import React from "react";
import ModalContainer from "./Components/ModalContainer";
import IconError from "../../assets/icons/IconError";

const ModalError = ({ handleCloseModal }) => {
  return (
    <>
      <ModalContainer
        title="Error en la Solicitud"
        id="error-request"
        ariaLabelledby="errorRequest"
        close={handleCloseModal}
        Button={
          <button
            type="button"
            className="btn_1"
            data-dismiss="modal"
            aria-hidden="true"
            onClick={handleCloseModal}
          >
            Cerrar
          </button>
        }
      >
        <div>
          <div style={{ textAlign: "center", padding: "0px 20px" }}>
            <div className="ui-error" style={{ margin: "5px 0px 15px 0px" }}>
              <IconError />
            </div>
            <h4>
              <span>Solicitud fallida!</span>
            </h4>
            <p>
              Al parecer ya has enviado una solicitud, por favor contactanos al
              correo office@upbirk.com, si no es así, intenta nuevamente.
            </p>
          </div>
        </div>
      </ModalContainer>
      <a
        id="error-request-piloto"
        data-toggle="modal"
        data-target="#error-request"
        style={{ display: "none" }}
      />
    </>
  );
};

export default ModalError;
