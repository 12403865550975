import React from "react";
import ButtonContainer from "../../Buttons/ButtonContainer";
import ButtonBack from "../../Buttons/ButtonBack";
import ButtonSubmit from "../../Buttons/ButtonSubmit";
import { SelectRegion } from "../../../Data/SelectRegion";
import { SelectWherePiloto } from "../../../Data/SelectWherePiloto";
import { SelectProjectStatus } from "../../../Data/SelectProjectStatus";
import { SelectCity } from "../../../Data/SelectCity";
import ModalSuccess from "../../Modal/ModalSuccess";
import ModalError from "../../Modal/ModalError";
import { SelectCountry } from "../../../Data/SelectCountry";

const Step5 = ({ formData, onSubmit }) => {
  const { step1, step2 } = formData;

  const handleBack = () => {
    document.getElementById("step2").style.display = "block";
    document.getElementById("step5").style.display = "none";
  };

  const handleCloseModal = () => {
    window.location.href = "/";
  };

  return (
    <div id="step5" className="submit step" style={{ display: "none" }}>
      <h3 className="main_question">
        <strong>3/3</strong>Resumen
      </h3>
      <div className="summary">
        <ul>
          <li>
            <strong>1</strong>
            <h5>Información Personal</h5>
            <div>
              <b>Nombre Completo:</b> {step1.fullname} <br />
              <b>Email:</b> {step1.email} <br />
              <b>Número de Teléfono:</b> {step1.phone} <br />
              <b>País:</b> {SelectCountry()[step1.country]} <br />
              {step1.country === "chile" ? (
                <>
                  <b>Región:</b> {SelectRegion[step1.region]} <br />
                  <b>Ciudad:</b>{" "}
                  {formData.step1.city
                    ? SelectCity[formData.step1.region][formData.step1.city]
                    : ""}{" "}
                  <br />
                </>
              ) : (
                <>
                  <b>Ciudad:</b> {step1.city_textfield} <br />
                </>
              )}
              <b>Edad:</b> {step1.age} <br />
            </div>
          </li>
          <li>
            <strong>2</strong>
            <h5>Información Académica</h5>
            <div>
              <b>Institución Educativa:</b> {step2.institution_education} <br />
              <b>Nivel Académico:</b> {step2.academy_level} <br />
              <b>¿Dónde conociste UPBIRK?:</b>{" "}
              {SelectWherePiloto[step2.where_piloto]} <br />
              <b>¿Tienes un Proyecto?:</b>{" "}
              {step2.has_project === "true" ? "Si" : "No"} <br />
              {step2.has_project === "true" ? (
                <>
                  <b>Estado del Proyecto: </b>
                  {SelectProjectStatus[step2.project_status]}
                  <br />
                </>
              ) : null}
              <b>¿Tienes experiencia como emprendedor?:</b>{" "}
              {step2.has_entrepreneur === "yes" ? "Si" : "No"} <br />
            </div>
          </li>
        </ul>
      </div>
      <ModalSuccess handleCloseModal={handleCloseModal} />
      <ModalError handleCloseModal={handleCloseModal} />
      <ButtonContainer>
        <ButtonBack onClick={handleBack} />
        <ButtonSubmit onClick={onSubmit} />
      </ButtonContainer>
      <div style={{ marginTop: "3.125rem" }}>
        <p style={{ marginBottom: "10px" }}>
          Agradecemos a los emprendimientos que provienen de:
        </p>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            gap: "10px",
            justifyContent: "center",
            flexWrap: "wrap",
          }}
        >
          <img src="img/draper-logo.png" alt="" width="80px" />
          <img src="img/eva-logo.png" alt="" width="80px" />
        </div>
      </div>
    </div>
  );
};

export default Step5;
