import React from "react";
import {} from "formik";

const CheckBox = ({
  name = "",
  onChange = () => {},
  onBlur = () => {},
  ...props
}) => {
  return (
    <input
      type="checkbox"
      name={name}
      onChange={onChange}
      onBlur={onBlur}
      {...props}
    />
  );
};

export default CheckBox;
