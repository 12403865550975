import React from "react";

const ModalFooter = ({ children }) => {
  return (
    <div class="modal-footer">
      {children}
    </div>
  );
};

export default ModalFooter;
