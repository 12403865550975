import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import ButtonNext from "../../Buttons/ButtonNext";
import ButtonContainer from "../../Buttons/ButtonContainer";
import { SelectCity } from "../../../Data/SelectCity";
import { SelectRegion } from "../../../Data/SelectRegion";
import { SelectCountry } from "../../../Data/SelectCountry";
import useForm from "../../../hooks/useForm";
import { Form, FormikProvider } from "formik";
import Textfield from "../../Inputs/Textfield";
import ErrorMessage from "../../FormikError/ErrorMessage";
import Select from "../../Inputs/Select";

const Step1 = ({ step1, setFormData }) => {
  const [currentRegion, setCurrentRegion] = useState("");
  const formSchema = Yup.object().shape({
    fullname: Yup.string().required("El campo es requerido").min(1),
    email: Yup.string()
      .min(1)
      .email("Ingrese un email válido")
      .required("El campo es requerido"),
    phone: Yup.string().required("El campo es requerido").min(1),
    country: Yup.string().required("El campo es requerido").min(1),
    region: Yup.string().when("country", {
      is: (value) => value === "chile",
      then: (schema) => schema.required("El campo es requerido").min(1),
    }),
    city: Yup.string().when("country", {
      is: (value) => value === "chile",
      then: (schema) => schema.required("El campo es requerido").min(1),
    }),
    city_textfield: Yup.string().when("country", {
      is: (value) => value !== "chile",
      then: (schema) => schema.required("El campo es requerido").min(1),
    }),
    age: Yup.string().required("El campo es requerido").min(1),
    gender: Yup.string().required("El campo es requerido").min(1),
  });

  const handleNextForm = (values) => {
    setFormData((formData) => ({ ...formData, step1: values }));
    document.getElementById("step1").style.display = "none";
    document.getElementById("step2").style.display = "block";
  };

  const formik = useForm(step1, formSchema, handleNextForm);
  const { values, errors, touched, handleChange, handleBlur } = formik;
  const countries = SelectCountry();

  useEffect(() => {
    setCurrentRegion(values.region);
  }, [values.region]);

  return (
    <FormikProvider value={formik}>
      <Form id="wrapped" autoComplete="off" noValidate>
        <div id="step1" className="step">
          <h3 className="main_question">
            <strong>1/3</strong>Información Personal
          </h3>
          <div className="form-group">
            <Textfield
              autoFocus={true}
              name="fullname"
              className="form-control required"
              placeholder="Nombre Completo"
              value={values.fullname}
              onChange={handleChange}
              onBlur={handleBlur}
            />
            <ErrorMessage error={errors.fullname} touched={touched.fullname} />
          </div>
          <div className="form-group">
            <Textfield
              type="email"
              name="email"
              className="form-control required"
              placeholder="Email"
              value={values.email}
              onChange={handleChange}
              onBlur={handleBlur}
            />
            <ErrorMessage error={errors.email} touched={touched.email} />
          </div>
          <div className="form-group">
            <Textfield
              type="number"
              name="phone"
              className="form-control required"
              placeholder="Número de Teléfono"
              value={values.phone}
              onChange={handleChange}
              onBlur={handleBlur}
            />
            <ErrorMessage error={errors.phone} touched={touched.phone} />
          </div>
          <div className="form-group">
            <Select
              name="country"
              value={values.country}
              placeholder="País"
              options={Object.entries(countries)}
              onChange={handleChange}
              onBlur={handleBlur}
            />
            <ErrorMessage error={errors.country} touched={touched.country} />
          </div>
          {values.country === "chile" && (
            <>
              <div className="form-group">
                <Select
                  name="region"
                  value={values.region}
                  placeholder="Región"
                  options={Object.entries(SelectRegion)}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                <ErrorMessage error={errors.region} touched={touched.region} />
              </div>
              <div className="form-group">
                <Select
                  name="city"
                  value={values.city}
                  placeholder="Ciudad de Residencia"
                  options={
                    currentRegion
                      ? Object.entries(SelectCity[currentRegion])
                      : []
                  }
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                <ErrorMessage error={errors.city} touched={touched.city} />
              </div>
            </>
          )}
          {values.country !== "chile" && values.country.length > 0 && (
            <div className="form-group">
              <Textfield
                type="text"
                name="city_textfield"
                className="form-control"
                placeholder="Ciudad de Residencia"
                value={values.city_textfield}
                onChange={handleChange}
                onBlur={handleBlur}
              />
              <ErrorMessage
                error={errors.city_textfield}
                touched={touched.city_textfield}
              />
            </div>
          )}
          <div className="row">
            <div className="col-3">
              <div className="form-group">
                <Textfield
                  type="number"
                  name="age"
                  className="form-control"
                  placeholder="Edad"
                  value={values.age}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                <ErrorMessage error={errors.age} touched={touched.age} />
              </div>
            </div>
            <div className="col-9">
              <div className="form-group radio_input">
                <label className="container_radio">
                  Masculino
                  <input
                    type="radio"
                    name="gender"
                    value="Male"
                    className="required"
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  <span className="checkmark"></span>
                </label>
                <label className="container_radio">
                  Femenino
                  <input
                    type="radio"
                    name="gender"
                    value="Female"
                    className="required"
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  <span className="checkmark"></span>
                </label>
                <label className="container_radio">
                  No Binario
                  <input
                    type="radio"
                    name="gender"
                    value="NoBinary"
                    className="required"
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  <span className="checkmark"></span>
                </label>
                <label className="container_radio">
                  Prefiero no decirlo
                  <input
                    type="radio"
                    name="gender"
                    value="PreferNotSay"
                    className="required"
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  <span className="checkmark"></span>
                </label>
              </div>
              <ErrorMessage error={errors.gender} touched={touched.gender} />
            </div>
          </div>
          <ButtonContainer>
            <ButtonNext />
          </ButtonContainer>
        </div>
      </Form>
    </FormikProvider>
  );
};

export default Step1;
