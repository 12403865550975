import React from "react";

const Textfield = ({
  autoFocus = false,
  type = "text",
  name = "",
  className = "",
  placeholder = "",
  value = "",
  onChange = () => {},
  onBlur = () => {},
}) => {
  return (
    <input
      autoFocus={autoFocus}
      type={type}
      name={name}
      className={className}
      placeholder={placeholder}
      value={value}
      onChange={onChange}
      onBlur={onBlur}
    />
  );
};

export default Textfield;
