import React from "react";

const LeftContent = () => {
  return (
    <>
      <div class="col-lg-6 col-sm-12 content-left">
        {/* <img
          src="img/upbirk_Fondo_sin_logos.png"
          style={{
            display: "block",
            position: "absolute",
            zIndex: "-100",
            width: "100%",
            height: "100%",
          }}
          alt="backgroundImage"
        /> */}
        <div className="content-left-wrapper">
          <div id="social">
            <ul>
              <li>
                <a
                  href="https://www.instagram.com/upbirkapp/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <i className="icon-instagram"></i>
                </a>
              </li>
              <li>
                <a
                  href="https://twitter.com/Upbirkapp"
                  target="_blank"
                  rel="noreferrer"
                >
                  <i className="icon-twitter"></i>
                </a>
              </li>
              <li>
                <a
                  href="https://www.linkedin.com/company/upbirk/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <i className="icon-linkedin"></i>
                </a>
              </li>
            </ul>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: "6vh",
            }}
          >
            <a href="https://www.upbirk.com" target="_blank" rel="noreferrer">
              <img src="img/Upbirk_blanco.png" alt="" width="200px" />
            </a>
          </div>
          <div className="content-left-text">
            <h2 style={{ fontSize: "1.75rem" }}>
            Te Invitamos a Ser Parte de la Beta de UPBIRK
            </h2>
            <p>
            Nos complace anunciar el lanzamiento de la versión beta de UPBIRK. Invitamos a aquellos interesados en participar en esta fase de pruebas a completar el formulario adjunto. Nos pondremos en contacto a la brevedad para otorgarles acceso exclusivo y recopilar sus valiosos comentarios. Agradecemos su colaboración y confianza en UPBIRK.
            </p>
            <p>
            </p>
          </div>
          <div>
            <div style={{ textAlign: "center" }}>
              <h6 className="text-white">Acceso Limitado a la Beta</h6>
            </div>
            <div style={{ textAlign: "center", marginBottom: 10, marginLeft: "10%", marginRight: "10%" }}>
              <p>La disponibilidad de acceso a la beta es limitada y se otorgará en función de la disponibilidad. Agradecemos su interés en participar.</p>
            </div>
          </div>

        </div>
      </div>
    </>
  );
};

export default LeftContent;
