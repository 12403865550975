import React from "react";

const ButtonSubmit = ({ onClick }) => {
  return (
    <button
      name="send-request-piloto"
      type="submit"
      className="submit"
      style={{ marginLeft: "5px" }}
      onClick={onClick}
    >
      Enviar
    </button>
  );
};

export default ButtonSubmit;
