import React from "react";
import * as Yup from "yup";
import { Form, FormikProvider } from "formik";
import Select from "../../Inputs/Select";
import Textfield from "../../Inputs/Textfield";
import ModalContainer from "../../Modal/Components/ModalContainer";
import ErrorMessage from "../../FormikError/ErrorMessage";

import useForm from "../../../hooks/useForm";
import ButtonNext from "../../Buttons/ButtonNext";
import ButtonBack from "../../Buttons/ButtonBack";
import ButtonContainer from "../../Buttons/ButtonContainer";
import { SelectWherePiloto } from "../../../Data/SelectWherePiloto";
import CheckBox from "../../Inputs/CheckBox";
import { SelectProjectStatus } from "../../../Data/SelectProjectStatus";

const Step2 = ({ step2, setFormData }) => {
  const formSchema = Yup.object().shape({
    institution_education: Yup.string()
      .required("El campo es requerido")
      .min(1),
    academy_level: Yup.string().required("El campo es requerido").min(1),
    where_piloto: Yup.string().required("El campo es requerido").min(1),
    has_project: Yup.boolean().nonNullable("El campo es requerido"),
    project_status: Yup.string().when("has_project", {
      is: (value) => value === true,
      then: (schema) => schema.required("El campo es requerido").min(1),
    }),
    has_entrepreneur: Yup.string().required("El campo es requerido").min(1),
    terms_conditions: Yup.boolean().isTrue("Debes aceptar los términos"),
  });

  const handleNextForm = (values) => {
    setFormData((formData) => ({ ...formData, step2: values }));
    document.getElementById("step2").style.display = "none";
    document.getElementById("step5").style.display = "block";
  };

  const formik = useForm(step2, formSchema, handleNextForm);

  const { values, errors, touched, handleBlur, handleChange, getFieldProps } =
    formik;

  const handleBackForm = () => {
    document.getElementById("step1").style.display = "block";
    document.getElementById("step2").style.display = "none";
  };

  return (
    <FormikProvider value={formik}>
      <Form id="wrapped" autoComplete="off" noValidate>
        <div id="step2" className="step" style={{ display: "none" }}>
          <h3 className="main_question">
            <strong>2/3</strong>Información Académica
          </h3>
          <div className="form-group">
            <Textfield
              autoFocus={true}
              name="institution_education"
              className="form-control required"
              placeholder="Institución Educativa"
              value={values.institution_education}
              onChange={handleChange}
              onBlur={handleBlur}
            />
            <ErrorMessage
              error={errors.institution_education}
              touched={touched.institution_education}
            />
          </div>
          <div className="form-group">
            <Textfield
              name="academy_level"
              className="form-control required"
              placeholder="Grado Académico Actual"
              value={values.academy_level}
              onChange={handleChange}
              onBlur={handleBlur}
            />
            <ErrorMessage
              error={errors.academy_level}
              touched={touched.academy_level}
            />
          </div>
          <div className="row" style={{ alignItems: "center" }}>
            <div className="col-5">
              <label>¿Tienes un Proyecto?</label>
            </div>
            <div className="col-7">
              <div className="form-group radio_input">
                <label className="container_radio">
                  Si
                  <input
                    type="radio"
                    name="has_project"
                    value={true}
                    className="required"
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  <span className="checkmark"></span>
                </label>
                <label className="container_radio">
                  No
                  <input
                    type="radio"
                    name="has_project"
                    value={false}
                    className="required"
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  <span className="checkmark"></span>
                </label>
              </div>
              <ErrorMessage
                error={errors.has_project}
                touched={touched.has_project}
              />
            </div>
          </div>
          {values.has_project === "true" && (
            <div className="row">
              <div className="col-12" style={{ marginBottom: "5px" }}>
                <label>Estado del Proyecto</label>
              </div>
              <div className="form-group col-12">
                <Select
                  name="project_status"
                  value={values.project_status}
                  placeholder="Seleccione el estado del proyecto"
                  options={Object.entries(SelectProjectStatus)}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                <ErrorMessage
                  error={errors.project_status}
                  touched={touched.project_status}
                />
              </div>
            </div>
          )}
          <div className="row">
            <div className="col-12">
              <label>¿Cómo te enteraste de este piloto?</label>
            </div>
            <div className="form-group col-12">
              <Select
                name="where_piloto"
                value={values.where_piloto}
                placeholder="Seleccione una opción"
                options={Object.entries(SelectWherePiloto)}
                onChange={handleChange}
                onBlur={handleBlur}
              />
              <ErrorMessage
                error={errors.where_piloto}
                touched={touched.where_piloto}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-3">
              <label>¿Has emprendido anteriormente?</label>
            </div>
            <div className="col-9">
              <div className="form-group radio_input">
                <label className="container_radio">
                  Si
                  <input
                    type="radio"
                    name="has_entrepreneur"
                    value="yes"
                    className="required"
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  <span className="checkmark"></span>
                </label>
                <label className="container_radio">
                  No
                  <input
                    type="radio"
                    name="has_entrepreneur"
                    value="no"
                    className="required"
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  <span className="checkmark"></span>
                </label>
              </div>
              <ErrorMessage
                error={errors.has_entrepreneur}
                touched={touched.has_entrepreneur}
              />
            </div>
          </div>
          <div className="form-group terms">
            <label className="container_check">
              <CheckBox
                name="terms_conditions"
                onChange={handleChange}
                onBlur={handleBlur}
                {...getFieldProps("terms_conditions")}
              />
              <span className="checkmark"></span>
              Acepto los{" "}
              <a href="#" data-toggle="modal" data-target="#terms-txt">
                términos y condiciones
              </a>{" "}
              del piloto.
            </label>
          </div>
          <ErrorMessage
            error={errors.terms_conditions}
            touched={touched.terms_conditions}
          />
          <ModalContainer
            title="Términos y Condiciones"
            id="terms-txt"
            ariaLabelledby="termsLabel"
            Button={
              <button
                type="button"
                className="btn_1"
                data-dismiss="modal"
                aria-hidden="true"
              >
                Cerrar
              </button>
            }
          >
            <p>
              Al aceptar participar en el piloto, comprendo que mis datos se
              emplearán con el propósito de investigación y desarrollo de la
              aplicación.
            </p>
          </ModalContainer>
          <ButtonContainer>
            <ButtonBack onClick={handleBackForm} />
            <ButtonNext />
          </ButtonContainer>
        </div>
      </Form>
    </FormikProvider>
  );
};

export default Step2;
