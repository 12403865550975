import React from "react";
import LeftContent from "./components/LeftContent";
import RightContent from "./components/RightContent";

const App = () => {
  return (
    <div class="container-fluid full-height">
      <div class="row row-height">
        <LeftContent />
        <RightContent />
      </div>
    </div>
  );
};

export default App;
