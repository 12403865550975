import React, { useState } from "react";
import axios from "../../utils/fech";
import { Step1, Step2, Step5 } from "./Steps";
import { SelectCity } from "../../Data/SelectCity";
import { SelectRegion } from "../../Data/SelectRegion";
import { SelectWherePiloto } from "../../Data/SelectWherePiloto";
import { SelectProjectStatus } from "../../Data/SelectProjectStatus";
import { SelectCountry } from "../../Data/SelectCountry";

const Form = () => {
  const [formData, setFormData] = useState({
    step1: {
      fullname: "",
      email: "",
      phone: "",
      country: "",
      region: "",
      city: "",
      city_textfield: "",
      age: "",
      gender: "",
    },
    step2: {
      institution_education: "",
      academy_level: "",
      where_piloto: "",
      has_entrepreneur: "",
      has_project: null,
      project_status: "",
      terms_conditions: false,
    },
  });

  const handleSubmit = async () => {
    const anchor = document.getElementById("sent-request-piloto");
    const anchorWithError = document.getElementById("error-request-piloto");
    const countries = SelectCountry();
    const parseData = {
      step1: {
        ...formData.step1,
        country: formData.step1.country
          ? countries[formData.step1.country]
          : null,
        region: formData.step1.region
          ? SelectRegion[formData.step1.region]
          : null,
        city: formData.step1.city
          ? SelectCity[formData.step1.region][formData.step1.city]
          : null,
      },
      step2: {
        ...formData.step2,
        where_piloto: SelectWherePiloto[formData.step2.where_piloto],
        has_entrepreneur:
          formData.step2.has_entrepreneur === "yes" ? "Si" : "No",
        has_project: formData.step2.has_project === "true" ? "Si" : "No",
        project_status:
          formData.step2.has_project === "true"
            ? SelectProjectStatus[formData.step2.project_status]
            : null,
      },
    };
    const headers = {
      email_destination: [
        "claudio.sanchez@upbirk.com",
        "new@upbirk.com",
        "piloto.platafoma@upbirk.com",
      ],
    };
    await axios
      .post("/piloto/udd/email", parseData, { headers })
      .then((res) => {
        if (res.status === 201) {
          anchor.click();
        }
      })
      .catch((err) => {
        if (err.response.status === 400) {
          anchorWithError.click();
        }
      });
  };

  return (
    <>
      <div id="middle-wizard">
        <Step1 step1={formData.step1} setFormData={setFormData} />
        <Step2 step2={formData.step2} setFormData={setFormData} />
        <Step5 formData={formData} onSubmit={handleSubmit} />
      </div>
    </>
  );
};

export default Form;
