import React from "react";

const ButtonNext = ({ onClick }) => {
  return (
    <button
      style={{ marginLeft: "5px" }}
      type="submit"
      name="forward"
      className="forward"
      onClick={onClick}
    >
      Siguiente
    </button>
  );
};

export default ButtonNext;
