import React from "react";
import ModalContainer from "./Components/ModalContainer";
import IconSuccess from "../../assets/icons/IconSuccess";

const ModalSuccess = ({ handleCloseModal }) => {
  return (
    <>
      <ModalContainer
        title="Solicitud enviada"
        id="success-request"
        ariaLabelledby="successRequest"
        close={handleCloseModal}
        Button={
          <button
            type="button"
            className="btn_1"
            data-dismiss="modal"
            aria-hidden="true"
            onClick={handleCloseModal}
          >
            Cerrar
          </button>
        }
      >
        <div>
          <div style={{ textAlign: "center", padding: "0px 20px" }}>
            <div
              className="icon icon--order-success svg"
              style={{ margin: "5px 0px 15px 0px" }}
            >
              <IconSuccess />
            </div>
            <h4>
              <span>Solicitud enviada con éxito!</span>
            </h4>
            <p>
              Expresamos nuestro sincero agradecimiento por tu participación en
              este piloto. Durante el transcurso de las próximas 48 horas,
              recibirá en su dirección de correo electrónico las credenciales de
              acceso a la plataforma UPBIRK. Si en algún momento surge alguna
              duda o pregunta, no dudes en ponerte en contacto con nosotros a
              través de la dirección de correo electrónico office@upbirk.com. Tu
              colaboración es de gran importancia para nosotros.
            </p>
          </div>
        </div>
      </ModalContainer>
      <a
        id="sent-request-piloto"
        data-toggle="modal"
        data-target="#success-request"
        style={{ display: "none" }}
      />
    </>
  );
};

export default ModalSuccess;
