import React from "react";

const ButtonBack = ({ onClick }) => {
  return (
    <button
      type="button"
      name="backward"
      className="backward"
      onClick={onClick}
    >
      Atrás
    </button>
  );
};

export default ButtonBack;
