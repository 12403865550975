import { useFormik } from "formik";

const useForm = (initialValues, validationSchema, onSubmit) => {
  const {
    values,
    errors,
    touched,
    isValid,
    handleBlur,
    handleChange,
    handleSubmit,
    handleReset,
    setFieldValue,
    setSubmitting,
    getFieldProps,
  } = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit: (values, actions) => onSubmit(values, actions),
  });

  return {
    values,
    errors,
    touched,
    isValid,
    handleBlur,
    handleChange,
    handleSubmit,
    handleReset,
    setSubmitting,
    setFieldValue,
    getFieldProps,
  };
};

export default useForm;
