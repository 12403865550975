export const SelectRegion = {
  I: "Arica y Parinacota",
  II: "Tarapacá",
  III: "Antofagasta",
  IV: "Atacama",
  V: "Coquimbo",
  VI: "Valparaíso",
  VII: "Región del Libertador Gral. Bernardo O’Higgins",
  VIII: "Región del Maule",
  IX: "Región de Ñuble",
  X: "Región del Biobío",
  XI: "Región de la Araucanía",
  XII: "Región de Los Ríos",
  XIII: "Región de Los Lagos",
  XIV: "Región Aisén del Gral. Carlos Ibáñez del Campo",
  XV: "Región de Magallanes y de la Antártica Chilena",
  RM: "Región Metropolitana de Santiago",
};
