import React from "react";
import Form from "./Forms/Form";

const RightContent = () => {
  return (
    <>
      <div className="col-lg-6 col-sm-12 content-right" id="start">
        <div id="wizard_container">
          <Form />
        </div>
      </div>
    </>
  );
};

export default RightContent;
