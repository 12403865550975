import React from "react";

const Select = ({
  name = "",
  value = "",
  onChange = () => {},
  onBlur = () => {},
  options = [],
  placeholder = "",
}) => {
  return (
    <div className="styled-select clearfix">
      <select
        className="nice-select wide required"
        name={name}
        value={value}
        onChange={onChange}
        onBlur={onBlur}
      >
        <option value="">{placeholder}</option>
        {options.map(([key, value]) => (
          <option value={key}>{value}</option>
        ))}
      </select>
    </div>
  );
};

export default Select;
