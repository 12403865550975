import React from "react";
import ModalHeader from "./ModalHeader";
import ModalBody from "./ModalBody";
import ModalFooter from "./ModalFooter";

const ModalContainer = ({
  title = "",
  Button = <div></div>,
  id,
  ariaLabelledby,
  children,
  close = () => {},
}) => {
  return (
    <div
      className="modal fade"
      id={id}
      tabindex="-1"
      role="dialog"
      aria-labelledby={ariaLabelledby}
      aria-modal="true"
    >
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <ModalHeader id={ariaLabelledby} title={title} onClick={close} />
          <ModalBody>{children}</ModalBody>
          <ModalFooter>{Button}</ModalFooter>
        </div>
      </div>
    </div>
  );
};

export default ModalContainer;
